.gastro-card-details {
  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12.5px;
    margin-top: 10px;
    padding: 10px 21px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.25rem;
      box-shadow: -2.5px 0px 0px 0px #b99153;
    }
  }

  &__factor {
    position: absolute;
    top: 50%;
    left: -23px;
    transform: translateY(-50%);
    box-shadow: rgb(255, 255, 255) 0 0 3px 4px, 0 0 7px 0 #000;
    width: 38px;
    height: 38px;
    color: $white;
    background-color: $gold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;

    @include screen-xs-max {
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__dynamic {
    &__deferred-payment-banner {
      border: 2px solid #fbd23a;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      padding: 15px;
      min-height: 86px;
      background-image: url('@assets/images/paypo-bg.webp');
      background-size: cover;
      background-position: 0 -15px;

      @include screen-md-max {
        text-align: center;
        flex-direction: column;
        background-position: center;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:118";