.manual-box {
  border: 2px solid rgba(185, 145, 83, 0.3);
  border-radius: 4px;
  padding: 30px 20px;
  align-items: start;
  display: grid;
  column-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 42px;
    width: calc(100% - 230px);
    height: 1px;
    border-top: 1px dashed $primary;
    transform: translateX(-50%);
    left: 50%;
    z-index: 0;
  }

  &__point {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    min-height: 25px;
    width: 25px;
    min-width: 25px;
    border-radius: 50%;
    background-color: $gold;
    color: white;
    font-weight: 500;
    position: relative;
    font-size: 12px;
    margin-right: 15px;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      left: -5px;
      top: -5px;
      background-color: $gold;
      opacity: 10%;
      border-radius: 50%;
    }
  }

  @include screen-xs-max {
    grid-template-columns: 1fr;

    &:after {
      content: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:116";