.circle-check {
  background-color: $gold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  min-width: 22px;
}

;@import "sass-embedded-legacy-load-done:111";