$holiday-benefit-primary: #d50c51;
$holiday-benefit-secondary: #22254b;
$holiday-benefit-tertiary: #951c81;
$holiday-benefit-gray: #e6e7e8;

.text-holiday-benefit-secondary {
  color: $holiday-benefit-secondary !important;
}

.text-holiday-benefit-primary {
  color: $holiday-benefit-primary !important;
}

.text-holiday-benefit-tertiary {
  color: $holiday-benefit-tertiary;
}

.bg-holiday-benefit-primary {
  background-color: $holiday-benefit-primary;
}

.bg-holiday-benefit-secondary {
  background-color: $holiday-benefit-secondary;
}

.bg-holiday-benefit-tertiary {
  background-color: $holiday-benefit-tertiary;
}

.bg-holiday-benefit-gray {
  background-color: rgba(230, 231, 232, 0.5);
}

.btn-holiday-benefit {
  box-shadow: none;
  border-radius: 4px;
  font-weight: 500;
  padding: 5px 30px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.is-primary {
    border: 0;
    background-color: $holiday-benefit-primary;
    color: #fff;

    &:hover {
      background-color: darken($holiday-benefit-primary, 10%);
    }
  }

  &.is-tertiary {
    border: 0;
    background-color: $holiday-benefit-tertiary;
    color: #fff;

    &:hover {
      background-color: darken($holiday-benefit-tertiary, 10%);
    }
  }

  &.is-dark {
    border: 0;
    background-color: #3f3f3f;
    color: #fff;

    &:hover {
      background-color: darken(#3f3f3f, 10%);
    }
  }
}

.holiday-benefit {
  color: $holiday-benefit-secondary;

  &__header {
    &__spacer {
      border-radius: 10px;
      width: 1px;
      height: 35px;
      background-color: #979797;
    }
  }

  &__hero {
    margin-top: 10px;
    padding: 110px 100px;
    background-image: url('@assets/images/holiday-benefit/landing-page/header.webp');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.is-employee {
      background-image: url('@assets/images/holiday-benefit/landing-page/header-employee.webp');

      @include screen-md-max {
        background-position: center;
        padding: 50px 20px;
        background-image: url('@assets/images/holiday-benefit/landing-page/header-employee-mobile.webp');
        min-height: 900px;
      }
    }

    @include screen-xl-max {
      background-position: center;
    }

    @include screen-md-max {
      background-position: center;
      padding: 50px 20px;
      background-image: url('@assets/images/holiday-benefit/landing-page/header-mobile.webp');
      min-height: 900px;
    }

    &__wrapper {
      background: linear-gradient(180deg, #fff 80%, $holiday-benefit-gray 80%);
    }
  }

  &__section-title {
    font-size: 36px;
    line-height: 36px;
  }

  &__price {
    position: relative;

    &.text-holiday-benefit-primary {
      &:after {
        background-color: $holiday-benefit-primary;
      }
    }

    &.text-holiday-benefit-tertiary {
      &:after {
        background-color: $holiday-benefit-tertiary;
      }
    }

    &:after {
      content: '';
      bottom: 20px;
      height: 2px;
      left: -7px;
      position: absolute;
      width: 75px;
      transform: rotate(-26deg);
      display: none;
    }

    &.is-crossed {
      &:after {
        display: block;
      }
    }
  }

  &__benefits {
    padding-top: 100px;
    background-color: #e6e7e8;
    padding-bottom: 70px;
    padding-inline: 0;

    &__point {
      min-height: 17px;
      min-width: 17px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 14px;
      margin-right: 5px;
      display: inline-flex;
      align-self: start;
    }
  }

  &__numbers {
    padding: 70px 0;

    &__box {
      border: 1px solid rgba(213, 12, 81, 0.1);
      background-color: rgba(213, 12, 81, 0.05);
      padding: 8px 20px;
      display: flex;
      align-items: center;
      border-radius: 10px;

      &__value {
        font-weight: 700;
        font-size: 50px;
      }
    }
  }

  &__resorts {
    background-color: $holiday-benefit-gray;

    &__pill {
      background: linear-gradient(0deg, rgba(188, 3, 67, 0.95) 0%, #d50c51 100%);
      color: white;
      padding: 15px 30px;
      font-size: $font-lg;
      border-radius: 30px;
    }

    &__gallery {
      display: grid;
      grid-template-columns: repeat(4, 280px);
      grid-auto-rows: 219px;
      grid-gap: 10px;
      width: 100%;
      margin-bottom: 150px;

      &__image {
        width: 100%;
        height: 100%;
        border-radius: 10px;

        &:nth-child(5) {
          grid-row: 2/4;
        }

        &:nth-child(7) {
          grid-column: 3/5;
        }

        &:nth-child(9) {
          height: calc(100% + 70px);
        }
      }
    }
  }

  &__costs {
    &__slider {
      width: 50%;
      margin: 0 auto;

      &__input {
        -webkit-appearance: none;
        width: 100%;
        height: 9px;
        border-radius: 10px;

        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 9px;
          cursor: pointer;
          background-color: rgba(149, 27, 129, 0.2);
          border-top: 1px solid rgba(213, 12, 81, 0.3);
          border-bottom: 1px solid rgba(213, 12, 81, 0.3);
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          height: 25px;
          width: 25px;
          background-color: white;
          border-radius: 50%;
          margin-top: -10px;
          border: 3px solid $holiday-benefit-primary;
          position: relative;
          z-index: 2;
        }
      }

      &__value {
        background-color: #fadee7;
        border-radius: 4px;
        padding: 13px 20px;
        position: relative;
        font-weight: 500;
        white-space: nowrap;

        &:after {
          content: '';
          position: absolute;
          bottom: -7px;
          left: 50%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 7px 0;
          border-color: #fadee7 transparent transparent transparent;
          transform: translateX(-50%);
        }
      }

      &__tooltip {
        position: absolute;
        bottom: 45px;
      }

      &__step-point {
        position: absolute;
        top: 6px;
        height: 5px;
        width: 5px;
        background-color: $holiday-benefit-primary;
        border-radius: 50%;
        pointer-events: none;
      }

      &__wrapper {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 25%;
          height: 9px;
          border: 1px solid rgba(213, 12, 81, 0.3);
          border-right: none;
          background-color: rgba(149, 27, 129, 0.2);
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          right: 0;
          width: 25%;
          height: 9px;
          border: 1px solid rgba(213, 12, 81, 0.3);
          border-left: none;
          background-color: rgba(149, 27, 129, 0.2);
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }

    &__card {
      background-color: rgba(149, 27, 129, 0.05);
      border: 1px solid rgba(213, 12, 81, 0.1);

      &__image {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }

  &__employee-voucher {
    background: linear-gradient(0deg, #aa1463 0%, #d50c51 100%);

    &__label {
      top: 40px;
      left: 60px;
      position: absolute;
      border-radius: 8px;
      background: $holiday-benefit-secondary;
      display: inline-flex;
      align-items: center;
      padding: 11px 17px;

      @include screen-lg-max {
        left: 50%;
        transform: translateX(-50%);
        top: -30px;
      }

      &__circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__contact-form {
    background-color: $holiday-benefit-gray;

    .form-label {
      color: $holiday-benefit-secondary !important;
      font-weight: 500;
    }

    .checkbox--small {
      .form-check-input {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
      }
    }

    .form-check-input {
      width: 18px;
      height: 18px;

      &:checked,
      &:indeterminate {
        background-color: $holiday-benefit-secondary;
        border-color: $holiday-benefit-secondary;
        & + .form-check-label {
          color: $holiday-benefit-secondary !important;
        }
      }
    }

    .form-check-label {
      color: $darker-gray;
    }

    .form-control {
      background: white;
      border: 1px solid $holiday-benefit-gray;
      border-radius: 8px;
      height: 50px;

      &::placeholder {
        font-size: $font-md;
        font-weight: normal;
      }
    }
  }

  &__faq {
    background-color: $holiday-benefit-gray;
    padding-block: 100px;

    &__accordion {
      .accordion-item {
        border-radius: 16px;
        background-color: $holiday-benefit-gray;
        border: none;
      }

      .accordion-collapse {
        transition: background-color 200ms linear, height 200ms linear;
        border-bottom-left-radius: 16px !important;
        border-bottom-right-radius: 16px !important;

        &.collapse {
          background-color: rgba(255, 255, 255, 0.7);
        }
      }

      .accordion-button {
        display: flex;
        justify-content: space-between;
        box-shadow: none;
        border: none;
        background-color: $holiday-benefit-gray;
        color: $holiday-benefit-secondary;
        transition: background-color 200ms linear;
        transition-delay: 200ms;
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;

        &:not(.collapsed) {
          background-color: rgba(255, 255, 255, 0.7);
          color: $holiday-benefit-primary;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &__deployment {
    &__arrow {
      &__step-1-2 {
        position: absolute;
        z-index: 1;
        right: 55px;
        bottom: -45px;
      }

      &__step-2-3 {
        position: absolute;
        z-index: 1;
        right: 22px;
        bottom: -47px;
      }
    }

    &__tools {
      &__image {
        position: absolute;
        left: 40px;
        bottom: 0;
        border-radius: 8px;
        object-fit: cover;
        max-width: 100%;

        @include screen-lg-max {
          position: relative;
          margin-left: 40px;
          left: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  &__invitation {
    &__steps {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px auto 30px auto;
      width: 100%;

      &__first:after {
        content: '';
        width: 40px;
        height: 1px;
        background-color: $darker-gray;
        margin-right: 15px;
      }

      &__circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid $holiday-benefit-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $holiday-benefit-primary;

        &.is-completed {
          border-color: $success;
          color: $success;
          width: 27px;
          height: 27px;
        }

        &.is-inactive {
          width: 27px;
          height: 27px;
          border-color: $darker-gray;
          color: $darker-gray;
        }
      }
    }

    &__slides {
      position: sticky;
      top: 37.5px;

      .package-wholesale__resort {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08);
      }

      .package-wholesale__resorts {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        margin-bottom: 0;

        .react-multi-carousel-dot {
          & > button {
            background-color: rgba(239, 243, 247, 0.5);
            opacity: 50%;
          }

          &--active > button {
            background-color: $light-blue;
            opacity: 100%;
          }
        }

        .react-multi-carousel-list {
          padding-bottom: 0;
        }

        .react-multi-carousel-dot-list {
          left: 50px;
          bottom: 80px;
        }
      }

      &__image {
        width: 100%;
        object-position: top;
        height: 90vh;
      }

      &__text {
        bottom: 120px;
        position: absolute;
        color: white;
        font-size: 20px;
        font-style: italic;
        opacity: 0.85;
        text-align: center;
        max-width: 350px;
        left: 55%;
        transform: translateX(-50%);
      }
    }

    &__header {
      flex: 1;
      display: flex;

      &:after {
        content: '';
        flex: 1;
      }
    }

    &__back-button {
      flex: 1;
    }

    &__card {
      cursor: pointer;
      position: relative;
      border-radius: 7px;
      border: 2px solid white;
      transition: border-color 200ms ease-in-out;
      text-align: start;
      padding: 0;

      background: rgba(213, 12, 81, 0.01);
      box-shadow: 8px 8px 24px 0 rgba(149, 27, 129, 0.01);

      @mixin benefitCardVariant($color) {
        border-color: $color;

        .holiday-benefit__invitation__card__selection:after {
          background-color: $color;
        }
      }

      &.is-active {
        &.is-primary {
          @include benefitCardVariant($holiday-benefit-primary);
        }

        &.is-secondary {
          @include benefitCardVariant($holiday-benefit-secondary);
        }

        &.is-tertiary {
          @include benefitCardVariant($holiday-benefit-tertiary);
        }

        .holiday-benefit__invitation__card__selection__check {
          display: block;
        }
      }

      &__selection {
        position: absolute;
        left: 10px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 8px;
        background-color: white;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 22px;
          height: 22px;
          background-color: white;
          border-radius: 4px;
          z-index: 0;
          transition: background-color 200ms ease-in-out;
        }

        &__check {
          display: none;
          color: white;
          font-size: 20px;
          z-index: 1;
        }
      }
    }

    &__account {
      &__separator {
        display: flex;
        justify-content: center;
        position: relative;
        margin: 10px 0;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: $light-blue;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          border-radius: 40%;
          z-index: 0;
        }

        &__text {
          border-radius: 4px;
          background-color: $light-blue;
          color: $holiday-benefit-primary;
          font-weight: 500;
          display: inline-block;
          padding: 2px 20px;
          z-index: 1;
          font-size: $font-sm;
        }
      }
    }

    &__selected-plan-banner {
      display: flex;
      background-color: rgba(149, 27, 129, 0.05);
      border-radius: 10px;

      &__image {
        height: 100%;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      &__data {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border-top: 1px solid rgba(213, 12, 81, 0.1);
        border-right: 1px solid rgba(213, 12, 81, 0.1);
        border-bottom: 1px solid rgba(213, 12, 81, 0.1);
      }
    }

    &__app {
      &__modal {
        &__close-btn {
          position: absolute;
          right: 0;
          top: 0;
          padding: 20px;
          border-radius: 0 5px 0 5px;
        }

        &__image {
          height: 100%;
          object-fit: cover;
          border-bottom-right-radius: 8px;

          @include screen-md-max {
            border-radius: 0;
            max-height: 250px;
            object-position: top;
          }
        }
      }
    }

    &__rules {
      .form-check-input:checked,
      .form-check-input:indeterminate {
        background-color: $holiday-benefit-primary;
        border-color: $holiday-benefit-primary;
      }

      .form-check-input:checked + .form-check-label {
        color: $darker-gray;
      }
    }
  }

  &__sticky-label {
    z-index: 1;
    position: fixed;
    top: 40px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    &__text {
      position: absolute;
      left: 13px;
      top: 110px;
      line-height: 1;
      text-orientation: mixed;
      writing-mode: vertical-rl;
      font-size: $font-lg;
      font-weight: 500;
      color: white;
      white-space: nowrap;
      z-index: 1;
    }
  }

  &__employee {
    &__enrolment {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);

      @include screen-md-max {
        display: block;
      }

      &__card {
        min-height: 300px;

        @include screen-md-max {
          min-height: auto;
          margin-block: 20px;
        }
      }

      &__step-1-2 {
        position: absolute;
        right: -27px;
        top: 26px;
        transform: rotate(-125deg);

        @include screen-md-max {
          right: 13px;
          top: auto;
          transform: rotate(0);
          bottom: -58px;
        }
      }

      &__step-2-3 {
        position: absolute;
        right: -29px;
        top: 40px;
        transform: rotateY(-185deg) rotate(60deg);

        @include screen-md-max {
          position: absolute;
          right: 20px;
          top: auto;
          transform: none;
          bottom: -58px;
        }
      }
    }

    &__mobile-banner {
      &__card {
        border-radius: 0.75rem;

        @include screen-md-max {
          border-radius: 0;
          margin-inline: -10px;
        }

        &.is-app-card {
          @include screen-md-max {
            overflow: hidden;
          }
        }
      }

      &__image {
        pointer-events: none;
        position: absolute;
        right: -78px;
        bottom: -21px;

        @include screen-md-max {
          position: relative;
          pointer-events: all;
          right: 200px;
          bottom: -60px;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:128";