.subscription-contract {
  &__payments-table {
    padding: 5px;
    width: 90%;

    th {
      color: $darker-gray;
      font-weight: 500;
      padding-inline: 10px;
      border-bottom: 1px solid #cdcfd2;
    }

    tbody {
      background: #f7fbfe;
      border-radius: 4px;
      border: 1px solid #cdcfd2;
    }

    td {
      font-weight: 500;
      padding: 10px 10px;
      border-bottom: 1px solid rgba(118, 130, 140, 0.2);
      color: $darker-gray;
    }

    &__last-row-data {
      border-bottom: none !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:122";