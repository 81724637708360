.app-header-section {
  position: relative;

  @include screen-lg-min {
    min-height: 615px;
    padding-bottom: 180px;
  }

  @include screen-xs-max {
    min-height: 100vh;
    padding-bottom: 180px;
  }

  &__icon {
    position: relative;
    z-index: 1;
    height: 40px;

    @include screen-xs-max {
      height: 30px;
    }
  }

  &__background {
    object-fit: cover;
    object-position: top;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &.is-video {
      filter: brightness(0.3);
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.is-gastro-card-slider {
        filter: brightness(0.5);
      }
    }

    .carousel-inner,
    .carousel-item {
      height: 100%;
    }

    .carousel-control-prev,
    .carousel-control-next {
      z-index: 2;
      width: 0;

      @include screen-lg-min {
        width: 5%;
      }

      @include screen-lg-max {
        display: none;
      }
    }
  }

  &__icons {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 50px 0 20px 0;

    @include screen-lg-min {
      padding: 75px 0;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 42px;
    font-weight: 700;

    @include screen-lg-max {
      font-size: 25px;
      line-height: 42px;
    }
  }

  &__after-content {
    width: 100%;

    @include screen-xs-max {
      margin-top: -200px;
      padding-bottom: 0;
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }

    @include screen-lg-min {
      margin-top: -200px;
      padding-bottom: 40px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:109";