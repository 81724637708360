.codes-deactivation {
  &__list {
    background-color: #f7fbfe;
    border: 1px solid #cdcfd2;
    border-radius: 4px;
    padding-inline: 30px;

    &__title {
      padding-inline: 30px;
    }

    &__item {
      padding: 10px;
    }

    &__item + &__item {
      border-top: 1px solid #cdcfd2;
    }
  }

  &__submit-button {
    position: absolute;
    right: 75px;
    top: 33px;
    transition: right 200ms ease-in-out;

    @include screen-md-max {
      right: 90px;
    }

    @include screen-xs-max {
      right: 40px;
    }

    &.is-moved {
      right: 100px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:123";