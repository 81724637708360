html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: white !important;
  font-size: 13px;
  color: $black-olive;

  * {
    font-family: museo_sans, sans-serif !important;
  }

  @include screen-xl-min {
    font-size: 14.5px;
  }

  @include screen-xs-min {
    font-size: 12.5px;
  }

  &.block-scroll {
    overflow: hidden;
    touch-action: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  @include screen-lg-max {
    background-color: white !important;
  }
}

//util classes

.cursor-pointer {
  cursor: pointer;
}

.text-dark-blue {
  color: $dark-blue;
}

.text-darker-gray {
  color: $darker-gray;
}

.text-black-olive {
  color: $black-olive;
}

.text-red {
  color: $red;
}

.text-primary {
  color: $primary;
}

.text-gold {
  color: $gold;
}

.bg-darker-gray {
  background: $darker-gray !important;
}

.bg-light-gray {
  background: $light-gray;
}

.bg-red {
  background: $red;
}

.bg-primary {
  background: $primary;
}

.bg-dark-blue {
  background: $dark-blue;
}

.bg-gold {
  background: $gold;
}

.bg-alice-blue {
  background-color: $alice-blue;
}

.bg-tropical-blue {
  background-color: $tropical-blue;
}

.bg-light-azure {
  background-color: $light-azure;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-lighter-gray {
  background: $lighter-gray;
}

.font-xxs {
  font-size: $font-xxs;
}

.font-xs {
  font-size: $font-xs;
}

.font-sm {
  font-size: $font-sm;
}

.font-md {
  font-size: $font-md;
}

.font-lg {
  font-size: $font-lg;
}

.font-xl {
  font-size: $font-xl;
}

.font-xxl {
  font-size: $font-xxl;
}

.font-size-xxl {
  font-size: 1.35rem;
}

.font-xxxl {
  font-size: $font-xxxl;
}

.font-700 {
  font-weight: 700 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-300 {
  font-weight: 300 !important;
}

.lh-initial {
  line-height: initial;
}

.card-shadow {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
}

.btn-darker-gray {
  background-color: $darker-gray;
  transition: background-color 0.3s;
  border: none;
  padding-inline: 0;

  &:hover {
    background-color: darken($darker-gray, 10%);
  }
}

.btn-responsive {
  width: 100%;

  @include screen-sm-min {
    width: auto;
  }
}

.image-responsive {
  height: auto;

  @include screen-lg-max {
    width: 100%;
    object-fit: cover;
    max-height: 500px;
  }
}

.btn-unstyled {
  border: none;
  background: none;
  padding-inline: 0;
}

.container {
  max-width: 1198px !important;
}

.underline-shadow {
  background-size: 1px 1em;
  box-shadow: inset 0 -0.175em white, inset 0 -8px rgba($gold, 0.3);
  display: inline;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.badge-success,
.badge-danger {
  padding: 2px 10px;
  border-radius: 5px;
}

.badge-success {
  border: 1px solid rgba(44, 143, 65, 0.4);
  background-color: rgba(44, 143, 65, 0.1);
  color: $success;
}

.badge-danger {
  border: 1px solid rgba(223, 22, 22, 0.4);
  background-color: rgba(223, 22, 22, 0.1);
  color: $danger;
}

.spinner-border {
  &.spinner-border-xs {
    width: 1rem;
    height: 1rem;
    border-width: 2px;
  }
}

.hidden-scrollbar {
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.object-fit-contain {
  object-fit: contain;
}

;@import "sass-embedded-legacy-load-done:6";