$gold: #b99153;
$dark-blue: #0f2453;
$light-azure: #ecf5fc;
$light-gray: #f0f4f8;
$light-blue: #eff3f7;
$darker-gray: #576067;
$black-olive: #3f3e3e;
$green-pearl: #2c8f41;
$red: #da001a;
$dark-gray-gradient: linear-gradient(#606a72, #444c52);
$gold-gradient: linear-gradient(#d0a35d, #b99153);
$light-muted: #c5c9cb;
$lighter-gray: #d8d8d840;
$alice-blue: #f0f7fb;
$tropical-blue: #d3e8f8;

// Breakpoints
$screen-xxl-min: 1198px;
$screen-xl-min: 1198px;
$screen-xl-max: ($screen-xxl-min - 1);
$screen-lg-min: 992px;
$screen-lg-max: ($screen-xl-min - 1);
$screen-md-min: 768px;
$screen-md-max: ($screen-lg-min - 1);
$screen-sm-min: 576px;
$screen-sm-max: ($screen-md-min - 1);
$screen-xs-min: 480px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-xxs-max: 375px;

// Fonts
$font-xxs: 0.8rem;
$font-xs: 0.85rem;
$font-sm: 0.9rem;
$font-md: 0.95rem;
$font-lg: 1.1rem;
$font-xl: 1.2rem;
$font-xxl: 1.45rem;
$font-xxxl: 2rem;

$primary: $gold;
$secondary: $dark-blue;
$success: $green-pearl;
$danger-transparent: #dc35451a;
$danger: $red;

$enable-negative-margins: true;

;@import "sass-embedded-legacy-load-done:4";