.reservation-details {
  &__payment-bill {
    &__separator {
      margin: 0 -3rem;
      color: $text-muted;
    }

    &__separator + &__separator {
      display: none;
    }
  }

  &__payment-schedule {
    &__bar {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 12px 10px;
      position: relative;

      @include screen-sm-min {
        justify-content: space-between;
        flex-direction: row;
      }

      &__percentage {
        position: absolute;
        top: -20px;
        font-weight: bold;
        color: $primary;
      }

      &.is-filled.is-rest-value {
        border: 1px solid #d9c097;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &.is-filled.is-base-value {
        border: 1px solid #bacddf;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &.is-empty.is-rest-value {
        display: none;
      }

      &.is-empty.is-base-value {
        display: none;
      }

      &.is-base-value {
        width: 30%;
        border: 1px solid #bacddf;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        background: repeating-linear-gradient(
          -45deg,
          rgba(186, 205, 223, 0.1),
          rgba(186, 205, 223, 0.1) 10px,
          rgba(186, 205, 223, 0.2) 10px,
          rgba(186, 205, 223, 0.2) 20px
        );

        .reservation-details__payment-schedule__bar__percentage {
          right: 10px;
          color: $secondary;
        }

        .reservation-details__payment-schedule__price {
          color: $secondary;
        }
      }

      &.is-rest-value {
        border: 1px solid #d9c097;
        border-left: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        width: 70%;
        background: repeating-linear-gradient(
          -45deg,
          rgba(217, 192, 151, 0.1),
          rgba(217, 192, 151, 0.1) 10px,
          rgba(217, 192, 151, 0.2) 10px,
          rgba(217, 192, 151, 0.2) 20px
        );
      }

      &.is-paid {
        opacity: 0.3;
        border: 1px solid rgba(46, 108, 0, 0.2);
        border-radius: 0.25rem 0 0 0.25rem;
        color: $success;

        background: repeating-linear-gradient(
          -45deg,
          rgba(12, 153, 35, 0.02),
          rgba(12, 153, 35, 0.02) 10px,
          rgba(12, 153, 35, 0.05) 10px,
          rgba(12, 153, 35, 0.05) 20px
        );

        .reservation-details__payment-schedule__bar__percentage,
        .reservation-details__payment-schedule__price {
          color: $success;
        }
      }

      &.is-paid + &.is-base-value {
        border-radius: 0;
      }

      &.is-to-pay {
        background: repeating-linear-gradient(
          -45deg,
          rgba(255, 0, 0, 0.02),
          rgba(255, 0, 0, 0.02) 10px,
          rgba(255, 0, 0, 0.05) 10px,
          rgba(255, 0, 0, 0.05) 20px
        );

        .reservation-details__payment-schedule__price {
          color: $danger;
        }
      }

      .reservation-details__payment-schedule__price {
        color: $primary;
      }
    }

    &__wrapper {
      margin-top: 30px;

      @include screen-xs-max {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .reservation-details__payment-schedule__bar__percentage {
          left: 0;
          right: auto;
        }

        .reservation-details__payment-schedule__bar {
          border-radius: 4px;
          border: none;
          width: 100% !important;
        }
      }
    }
  }

  &__side-card {
    &__image {
      object-fit: cover;
      object-position: center;
      max-height: 320px;
    }

    &__remaining-time {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: $font-xs;
      font-weight: bold;
    }
  }

  &__payment-status {
    background-color: #fbe5e8;
    border: 1px solid #f2abb4;
    display: inline-block;
    border-radius: 4px;
    color: $danger;

    &.is-paid {
      background-color: #e9f3eb;
      border: 1px solid #bedbc4;
      color: $success;
    }

    &.is-mobile-full-width {
      @include screen-xs-max {
        display: flex;
        justify-content: center;
        padding: 10px;
        margin: 10px 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:121";