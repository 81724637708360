@import 'reset';
@import 'normalize';

@import 'fonts';
@import 'variables';
@import 'media-queries';
@import 'general';
@import '~bootstrap/scss/bootstrap';
@import 'animations';

@import 'mixins/animations';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'react-multi-carousel/lib/styles.css';
@import 'transitions';
@import 'not-found';

@import 'components/components';

@import 'package-wholesale/style';
@import 'gastro-card-details/styles';
@import 'gastro-coupon-details/styles';
@import 'vip-details/styles';
@import 'reservation-details/styles';
@import 'subscription-contract/styles';
@import 'codes-deactivation/styles';
@import 'protected/styles';
@import 'subscription-with-benefits/styles';
@import 'rodo-agreements/styles';
@import 'bocian-voucher/styles';
@import 'holiday-benefit/styles';
@import 'endless-holiday/styles';
