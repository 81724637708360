.gastro-coupon {
  &__side-card-label {
    background-color: red;
    border-top-right-radius: 0.45rem;
    border-bottom-right-radius: 0.45rem;
    position: absolute;
    left: 0;
    top: 25px;
    color: white;

    &__percent {
      font-size: 28px;
      line-height: 28px;
      display: block;
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;

    @include screen-md-min {
      flex-direction: row;
    }

    &__price {
      font-size: $font-xxxl;
      margin-top: 15px;
      display: block;
    }

    &__image {
      width: 100%;
      max-height: 200px;
      border-radius: 4px 4px 0 0;
      object-fit: cover;

      @include screen-md-min {
        width: 30%;
        border-radius: 4px 0 0 4px;
      }
    }

    &__description {
      background-color: $gold;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 10px 40px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include screen-md-min {
        align-items: flex-start;
      }
    }

    &__declared-price {
      padding-block: 11px;
      padding-left: 15px;
      padding-right: 15px;
      color: white;
      border-radius: 4px 4px 0 0;
      background-color: rgba(236, 245, 252, 0.3);
      text-align: center;

      @include screen-sm-min {
        border-radius: 4px 0 0 4px;
        text-align: left;
      }
    }

    &__bonus {
      margin-inline: 2px;
      position: relative;
      padding-block: 11px;
      padding-inline: 17px;
      color: white;
      display: flex;
      align-items: center;
      height: 90px;
      text-align: center;

      @include screen-sm-min {
        height: auto;
        padding-inline: 10px;
        text-align: left;
      }

      &__bg {
        position: absolute;
        top: 0;
        transform: rotate(90deg);
        width: 70px;
        left: 10px;

        @include screen-sm-min {
          transform: rotate(0);
          width: 100%;
          height: 100%;
          left: 0;
        }
      }
    }

    &__extra-price {
      padding-block: 11px;
      padding-right: 30px;
      padding-left: 15px;
      color: $secondary;
      background-color: rgba(236, 245, 252, 0.3);
      background-color: white;
      border-radius: 0 0 4px 4px;
      text-align: center;

      @include screen-sm-min {
        border-radius: 0 4px 4px 0;
        text-align: left;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:119";