.app-footer {
  &__background {
    background-image: url('@assets/images/logo_shape.png');
    background-repeat: no-repeat;
    background-position: center 0;
  }

  &__terms-wrapper {
    border-top: 1px solid #f4f4f4;
    background-color: white;

    &__content {
      max-width: 600px !important;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  }
}

;@import "sass-embedded-legacy-load-done:108";