.payment-selection {
  z-index: 1;

  &__grid {
    max-width: 980px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include screen-md-min {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    & > .payment-method {
      padding: 15px;
      text-align: center;
      border: 1px solid #cdcdcd;
      margin: 0 -1px -1px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .payment-method {
      transition: box-shadow 200ms;
      min-height: 50px;

      &__image {
        max-width: 100%;
        object-fit: cover;
        max-height: 30px;
        opacity: 0;
        transition: opacity 200ms linear;

        &.is-loaded {
          opacity: 1;
        }
      }

      &.is-selected {
        box-shadow: inset 0 0 2px 0 black;
      }
    }
  }

  &__button {
    margin-top: 18px;
    font-size: 14px;
    font-weight: bold;
    height: 47px;
    width: 230px;
  }

  &__expandable-privacy {
    margin: 20px auto 0 auto;
    max-height: 30px;
    font-size: 10px;
  }
}

;@import "sass-embedded-legacy-load-done:106";