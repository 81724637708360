.booking-offers {
  margin-top: 120px;

  @include screen-sm-max {
    margin-top: 40px;
  }

  &__season {
    &--winter {
      margin-right: 10px;
      color: rgba(24, 139, 172, 0.4);

      @include screen-sm-max {
        font-size: $font-sm;
      }
    }

    &--summer {
      margin-left: 10px;
      color: #7c5315;

      @include screen-sm-max {
        font-size: $font-sm;
      }
    }
  }

  &__card {
    background: white;
    position: relative;
    text-decoration: none;
    color: $darker-gray;
    display: block;
    border-radius: 20px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    transition: all 200ms ease-in-out;

    &__image {
      object-fit: cover;
      height: 100px;
      min-height: 100px;
      width: 100%;
    }

    &__season-signature {
      &__wrapper {
        position: absolute;
        background-color: white;
        border-radius: 4px;
        left: 5px;
        top: 5px;
        height: 23px;
        width: 23px;
        z-index: 1;
      }

      background-size: cover;
      position: absolute;
      left: 3px;
      top: 3px;
      width: 17px;
      height: 17px;
      background-image: url('@assets/images/snowflake.svg');
      z-index: 2;

      &.is-summer {
        background-image: url('@assets/images/sun.svg');
        height: 23px;
        width: 23px;
        left: 0;
        top: 0;
      }
    }

    &__inactive-banner {
      z-index: 2;
      background-color: rgba(87, 96, 103, 0.8);
      opacity: 0;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: -10px;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      transition: left 200ms linear, opacity 200ms linear;

      &.is-visible {
        left: 0;
        opacity: 1;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:115";