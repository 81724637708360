.protected {
  &__form {
    @include fadeInAnimation;
  }

  &__background {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  &__card {
    border-radius: 4px;
    background-color: white;
    width: 600px;
    max-width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;

    @include screen-sm-max {
      top: 40%;
    }

    &__content {
      width: 100%;
    }

    &__title {
      font-size: $font-xxl;
      color: $primary;
      font-weight: bold;
      margin-block: 15px;
      margin-left: 30px;
      position: relative;

      &:after {
        content: '';
        width: calc(100% + 30px);
        height: 1px;
        background-color: #e6e7e8;
        display: block;
        margin-top: 15px;
        margin-left: -30px;
      }
    }

    &__login-box {
      margin-inline: 30px;
    }

    &__input {
      width: 100%;

      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }

      @include screen-sm-max {
        .form-control {
          border-right: 1px solid #9099a1;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    &__submit {
      color: white;
      font-weight: bold;
      font: $font-xl;
      background-color: $dark-blue;
      border: 0;
      border-radius: 0 4px 4px 0;
      padding-inline: 20px;
      height: 48px;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include screen-sm-max {
        justify-content: center;
        width: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .spinner-border {
        border-width: 1px;
      }
    }
  }

  &__side-image {
    object-fit: cover;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 300px;
    width: 185px;

    @include screen-sm-max {
      display: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:124";