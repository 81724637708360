$subscription-lux: #fbb302;
$subscription-comfort: #e95442;
$subscription-optimum: #af81c6;

.subscription-with-benefits {
  .subscription-variant__header {
    &.is-lux {
      color: $subscription-lux;
    }

    &.is-comfort {
      color: $subscription-comfort;
    }

    &.is-optimum {
      color: $subscription-optimum;
    }
  }

  &__details {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.075) !important;

    &.is-lux {
      border-color: $subscription-lux !important;

      .subscription-variant__header {
        color: $subscription-lux;
      }
    }

    &.is-comfort {
      border-color: $subscription-comfort !important;

      .subscription-variant__header {
        color: $subscription-comfort;
      }
    }

    &.is-optimum {
      border-color: $subscription-optimum !important;

      .subscription-variant__header {
        color: $subscription-optimum;
      }
    }
  }
}

.package-wholesale__information__button {
  width: 100%;
  display: flex;
  justify-content: center;

  @include screen-sm-min {
    width: auto;
    justify-content: flex-start;
  }
}

;@import "sass-embedded-legacy-load-done:125";