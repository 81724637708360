.confetti-wrapper {
  z-index: 1;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 100%;
  pointer-events: none;
  position: absolute;
}

;@import "sass-embedded-legacy-load-done:92";