@mixin buttonColor($color) {
  background-color: $color;

  &:before {
    border-bottom-color: $color;
  }
}

.trapezoid {
  &__wrapper {
    padding-left: 25px;
    overflow: hidden;
    margin: 0;
  }

  &__button {
    min-height: 40px;
    background-color: $red;
    padding: 7px 17px 7px 40px;
    border-radius: 50px 0 3px 0;
    position: relative;
    border: none;
    transition:
      opacity 0.3s ease,
      background-color 0.3s ease;

    &,
    &:hover {
      color: $white;
    }

    &,
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:before {
      content: '';
      display: block;
      height: 0;
      width: 0;
      border: 30px solid transparent;
      border-bottom-color: $red;
      position: absolute;
      bottom: 0;
      left: -17px;
      transition: border-bottom-color 0.3s ease;
    }

    &.is-disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    }

    &.is-active {
      color: $text-muted;
      @include buttonColor(#e6e7e8);
    }

    &.is-primary {
      @include buttonColor($primary);
    }

    &.is-secondary {
      @include buttonColor($secondary);
    }

    &.is-success {
      @include buttonColor($green-pearl);
    }

    &.is-danger {
      @include buttonColor($danger);
    }

    &.is-light {
      color: $text-muted;
      @include buttonColor(#e6e7e8);
    }

    &.is-dark {
      color: white;
      @include buttonColor(#3f3f3f);
    }
  }
}

;@import "sass-embedded-legacy-load-done:114";