.package-wholesale {
  &__data-input {
    align-items: center;

    @include screen-sm-min {
      display: flex;
    }

    .form-control-with-status__container {
      @include screen-xs-min {
        width: 100%;
      }
    }
  }

  &__details-button {
    background: #3f3f3f;
    padding: 10px 40px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    justify-content: center;

    &:hover,
    &:active {
      background-color: #3f3f3f !important;
    }

    @include screen-xs-max {
      width: 100%;
    }

    &__wrapper {
      width: 100%;
    }

    &.is-label {
      position: absolute;
      z-index: 1;
      top: 315px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }

  &__video-wrapper {
    max-width: 494px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    column-gap: 11px;
    row-gap: 15px;

    &__card {
      transition: 0.3s;
      border: 1px solid transparent;
      height: fit-content;
      border-radius: 4px;
      color: white;
      max-width: 245px;

      &__description {
        font-size: $font-xs;
      }

      &__standard-price {
        &__title {
          font-size: 10px;
          line-height: 11px;
        }

        &__price {
          margin-top: 5px;
          font-size: $font-sm;
          position: relative;

          &:after {
            transition: border-top-color 200ms linear;
            content: '';
            position: absolute;
            border-top: 1px solid red;
            width: 115%;
            left: 0;
            bottom: 0;
            transform: rotate(-20deg);
            transform-origin: 0 0;
          }
        }
      }

      &__image {
        height: 165px;
        background-size: cover;
        position: relative;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &__costs {
        padding: 14px 11px 0 11px;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &__costs,
      &__content {
        background-color: $primary;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 700;
        transition: background-color 200ms linear;
      }

      &__content {
        padding: 10px 5px 14px 10px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        position: relative;
        z-index: 1;
      }

      &.is-disabled {
        border: 1px solid #c8ccd0;
        color: $darker-gray;
        cursor: pointer;

        .package-wholesale__cards__card__standard-price__price {
          &:after {
            border-color: black;
          }
        }

        .package-wholesale__cards__card__costs,
        .package-wholesale__cards__card__content {
          background-color: #eff3f7;
        }
      }

      &__option-description {
        font-size: $font-sm;
        opacity: 80%;
        margin-left: 29px;
      }
    }
  }

  &__resorts {
    scroll-margin-top: 30px;
    color: white;
    margin-bottom: 60px;
    background-color: $gold;
    box-shadow: inset -11px 0px 24px -13px rgb(163, 109, 34);
    padding: 30px 30px 0 30px;

    &__item {
      padding: 0 5px;
    }

    .react-multi-carousel-list {
      padding-bottom: 50px;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 22px 11px;
    }

    .react-multi-carousel-dot {
      & > button {
        border-radius: 50%;
        border: none;
        background-color: $light-blue;
        opacity: 50%;
        width: 8px;
        height: 8px;
      }

      &--active > button {
        background-color: white;
        opacity: 100%;
      }
    }

    .react-multi-carousel-dot-list {
      bottom: 10px;
    }

    .react-multiple-carousel__arrow {
      background: transparent;

      &--right {
        right: -5px;
      }

      &--left {
        left: -5px;
      }
    }

    .image-gallery-index {
      padding: 7px 15px;
      border-radius: 2px;
    }
  }

  &__resort {
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    overflow: hidden;
  }

  &__invoice {
    &__company-info {
      font-size: 12px;
    }

    &__gus-button {
      height: 48px;
      color: white;
      border: 0;
      border-radius: 0.25rem;
      background: linear-gradient(180deg, #606a72 0%, #444c52 100%);
    }
  }
}

@include screen-md-max {
  .package-wholesale__cards {
    grid-template-columns: 1fr 1fr;

    &__card {
      max-width: 100%;
    }
  }
  .package-wholesale__resorts__wrapper {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

@include screen-sm-max {
  .package-wholesale__cards {
    display: grid !important;
  }

  .package-wholesale__resorts__wrapper {
    grid-template-columns: 1fr 1fr !important;
  }
}

@include screen-xs-max {
  .package-wholesale__resorts__wrapper {
    display: block !important;
  }

  .package-wholesale__resort {
    margin-bottom: 20px;
  }

  .package-wholesale__cards {
    grid-template-columns: 1fr;
  }
}

.package-wholesale__gastro-card {
  width: 100%;
  background-color: #eff3f7;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  border: 1px solid #c8ccd0;
  transition: background-color 200ms linear;

  &__text {
    transition: color 200ms linear;
    color: $darker-gray;
  }

  &__bonus {
    margin-inline: 2px;
    position: relative;
    padding-block: 11px;
    padding-inline: 17px;
    color: $darker-gray;
    display: flex;
    align-items: center;
    height: 90px;
    text-align: center;

    &.is-active {
      color: white;
    }

    @include screen-sm-min {
      height: auto;
      padding-inline: 10px;
      text-align: left;
    }

    &__bg {
      position: absolute;
      top: 0;
      transform: rotate(90deg);
      width: 70px;
      left: 10px;

      @include screen-sm-min {
        transform: rotate(0);
        width: 100%;
        height: 100%;
        left: 0;
      }
    }
  }

  .spin-input__container {
    width: 100px;

    @include screen-sm-max {
      width: 100%;
      max-width: 100%;
    }
  }

  @include screen-sm-max {
    display: flex;
    flex-direction: column;
  }

  &.is-active {
    pointer-events: all;
    position: relative;
    background-color: $gold;
    border: none;

    .package-wholesale__gastro-card__text {
      color: white;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      background-color: white;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:before {
      content: '\2713';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 18px;
      height: 18px;
      background-color: $danger;
      border-radius: 4px;
      z-index: 1;
      color: white;
      padding-left: 4px;
      padding-top: 3px;
      font-size: 8px;
      font-weight: bold;
    }
  }

  &__image {
    object-fit: cover;
    width: 35%;

    @include screen-sm-max {
      width: 100%;
      max-height: 140px;
    }
  }

  &__values {
    display: flex;
    position: relative;

    &__multiplier {
      position: absolute;
      left: 45%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid $gold;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 2px;
      font-size: $font-sm;
      font-weight: bold;

      @include screen-sm-max {
        left: 48%;
      }
    }
  }

  &__amount-paid {
    padding-block: 10px;
    padding-left: 10px;
    padding-right: 15px;
    background-color: #c8af85;
    border-radius: 4px;
  }

  &__amount-final {
    padding-block: 10px;
    padding-left: 35px;
    padding-right: 10px;
    margin-left: 1px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include screen-sm-max {
      padding-left: 20px;
    }
  }

  &__amount-input {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  &__price-custom {
    height: 35px;
    width: 70px;
    background-color: white !important;
  }

  &__side-card {
    margin-top: 200px;
  }
}

.package-wholesale__information {
  max-height: 70px;
  transition: max-height 200ms linear;
  overflow: hidden;
  position: relative;

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, #ffffff70 100%);
    transition: opacity 200ms linear;
    opacity: 1;
  }

  &__toggle__icon {
    transition: transform 200ms linear;
  }

  &__wrapper {
    &.is-full-height {
      .package-wholesale__information {
        height: auto;
        max-height: initial;

        &:before {
          opacity: 0;
        }
      }
    }

    &.is-collapsed {
      .package-wholesale__information {
        max-height: 900px;

        &:before {
          opacity: 0;
        }
      }

      .package-wholesale__information__toggle__icon {
        transform: rotate(180deg);
      }
    }
  }
}

$subscription-gold: #d4a045;
$subscription-silver: #83898e;
$subscription-brown: #936920;

.subscription-variant {
  &__header {
    margin-bottom: 0;

    &.is-gold {
      color: $subscription-gold;
    }

    &.is-silver {
      color: $subscription-silver;
    }

    &.is-brown {
      color: $subscription-brown;
    }
  }

  &__expandable-row {
    & + & {
      margin-top: 10px;
    }

    .accordion-header {
    }

    .accordion-button {
      box-shadow: none;
      background-color: $light-blue;
      transition: background-color 300ms linear;

      &:not(.collapsed) {
        background-color: white;
      }
    }

    .accordion-item {
      &.is-gold {
        border-color: $subscription-gold;
      }

      &.is-silver {
        border-color: $subscription-silver;
      }

      &.is-brown {
        border-color: $subscription-brown;
      }
    }
  }

  &__price-side-card {
    position: sticky;
    top: 15px;

    &__image {
      position: relative;
      object-fit: cover;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &__content {
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      color: white;
      background-color: $darker-gray;
    }

    &__summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #4e565e;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:117";