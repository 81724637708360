.vip-details {
  &__wrapper {
    column-count: 1;

    @include screen-sm-min {
      column-count: 2;
    }

    @include screen-lg-min {
      column-count: 3;
    }
  }
}

.snow-layer {
  left: -20px;
  top: -40px;
  width: calc(100% + 22px);
  z-index: 11;
  position: absolute;

  @include screen-lg-max {
    top: -32px;
  }

  @include screen-md-max {
    top: -30px;
  }

  @include screen-xs-max {
    top: -15px;
    left: -5px;
    width: calc(100% + 5px);
  }
}

.snow-flake {
  animation: fall linear infinite, sway linear infinite;
  color: #efefef;
  position: absolute;
}

@keyframes fall {
  0% {
    opacity: 20%;
  }
  50% {
    opacity: 50%;
  }
  80% {
    opacity: 1;
  }

  100% {
    top: 100vh;
    opacity: 0;
  }
}

@keyframes sway {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 50px;
  }
  50% {
    margin-left: -50px;
  }
  75% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}

;@import "sass-embedded-legacy-load-done:120";