.rodo-agreements {
  &__view {
    height: 100vh;
    max-width: 1400px;
    margin: 0 auto !important;

    @include screen-sm-max {
      height: auto;
    }
  }

  &__description-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    height: 100vh;
    padding-top: 40px;
    padding-bottom: 40px;

    @include screen-sm-max {
      min-height: 100vh;
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
      overflow-y: auto;
      justify-content: flex-start;
    }

    &__image {
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      border-radius: 50px;
      margin-left: auto;
      object-fit: cover;
      min-height: 400px;
      height: 100%;
      width: 100%;
      max-height: calc(100vh - 80px);

      @include screen-sm-max {
        height: 300px;
        min-height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 60px;
      }
    }

    &__icon {
      max-height: 45px;
      object-fit: contain;
    }

    &__title {
      font-weight: 700;
      font-size: 4rem;

      @include screen-sm-max {
        font-size: 2rem;
        white-space: initial;
        justify-content: center;
      }

      & > div {
        @include screen-sm-max {
          justify-content: center;
        }
      }
    }

    &__subtitle {
      font-size: $font-xxl;

      @include screen-sm-max {
        font-size: $font-xl;
      }
    }

    &__button {
      background-color: $secondary;
      border-radius: 50px;
      color: white;
      padding: 20px 70px;
      border: none;
      font-weight: 500;
      font-size: 25px;
      box-shadow: 0 18px 43px -10px rgba(150, 150, 150, 0.88);

      @include screen-sm-max {
        font-size: 1rem;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:active {
        transform: scale(0.98);
        transition: transform 0.1s ease;
      }
    }
  }

  &__summary-page {
    &__title {
      font-size: 2.5rem;

      @include screen-sm-max {
        font-size: 2rem;
      }
    }
  }

  &__back-button__wrapper {
    &:before {
      border-radius: 50%;
      background: white;
      content: '';
      position: absolute;
      left: -68px;
      top: 37px;
      height: 80px;
      width: 80px;
      min-width: 80px;
      min-height: 80px;
    }
  }

  &__back-button {
    background-color: $secondary;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 50%;
    box-shadow: none;
    font-size: 40px;
    border: none;
    position: absolute;
    left: -63px;
    top: 42px;

    &__counter {
      position: absolute;
      top: 59px;
      left: 25px;
      font-weight: 500;
      color: $text-muted;
    }
  }

  &__qr-modal {
    &__close-button {
      font-size: 40px;
      line-height: 1;
    }

    .modal-dialog {
      max-width: 700px;
    }

    .modal-content {
      border-radius: 50px;
    }
  }

  &__qr-code-box {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

;@import "sass-embedded-legacy-load-done:126";