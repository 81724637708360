.custom-alert {
  display: inline-flex;
  align-items: center;
  border: 1px solid rgba(124, 139, 152, 0.5);
  padding-block: 5px;
  padding-left: 7px;
  padding-right: 10px;

  &--info {
    background-color: rgba(236, 245, 252, 0.5);
  }
}

;@import "sass-embedded-legacy-load-done:113";